<template>
  <div class="mt-4">
    <div class="d-flex mt-4 align-items-center justify-content-between">
      <h1 class="page-title">
        <b-icon icon="file-lock2-fill" />
        {{ $t("changePassword.title") }}
      </h1>
    </div>
    <b-overlay :opacity="0.95" :show="loading" rounded="sm">
      <b-card class="mt-4 shadow-sm">
        <div v-if="emailSent">
          <p>{{ $t("resetPasswordEmail.successMessage") }}</p>
        </div>

        <div v-else>
          <p>{{ $t("changePassword.textInfo") }}</p>
          <div class="d-flex align-items-center justify-content-end">
            <b-button variant="primary" pill v-on:click="resetPassword">
              {{ $t("resetPasswordEmail.reset") }}
            </b-button>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "Password",
  data() {
    return {
      loading: false,
      email: this.$store.getters.email,
      emailSent: false,
    };
  },
  methods: {
    // Set new password
    async resetPassword() {
      try {
        this.loading = true;
        await this.$http.post(
          this.$cfg.BASE_IDENTITY_VERSION_URL + "/reset-password",
          {
            activeMainEmail: this.email,
          }
        );
        this.emailSent = true;
      } catch (err) {
        if (err.response) console.log(err.response);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
